let _shuffle = require('lodash/shuffle');
let _merge = require('lodash/merge');
const anime = require('animejs');

export class Slots {
    constructor(element, delay = 0, timeBetween = 3000) {
        this.config = {
            timeBetween: timeBetween,
            delay: delay,
            height: 0,
            amountOfRows: 6,
            offestBottom: 6
        };
        this.wrapper = null;
        this.el = element;
        this.dataOrginal = JSON.parse(element.dataset.slotOptions);
        this.setAmountOfData();
        this.createSlotWrapper();
        this.setFirstElement();
        this.setPlaceholder();

        setTimeout(() => {
            this.rotate();
            this.setStyle();
        }, delay);

        let _this = this;
        window.addEventListener('resize', () => this.setStyle());

    }


    setPlaceholder() {
        this.placeholder = document.createElement('div');
        this.placeholder.classList.add('slot-placeholder');
        this.el.appendChild(this.placeholder);

        this.insertNewRecords(this.placeholder, this.dataOrginal)

    }


    setFirstElement() {
        let firstEl;
        if (this.el.dataset.hasOwnProperty('slotDefault')) {
            firstEl = this.el.dataset.slotDefault;
        } else {
            firstEl = this.dataOrginal[0];
        }
        this.createElement(this.wrapper, firstEl);

    }

    // set all in array for longer animation
    setAmountOfData() {
        let data = [];
        let orignalAmount = this.dataOrginal.length;
        let minAmount = Math.floor(this.config.amountOfRows / orignalAmount);
        for (let i = 0; i < minAmount; i++) {
            for (let y = 0; y < this.dataOrginal.length; y++) {
                data.push(this.dataOrginal[y])
            }
        }

        let diff = (this.config.amountOfRows) - data.length;
        for (let x = 0; x < diff; x++) {
            data.push(this.dataOrginal[x])
        }
        this.data = data;

    }

    // setHeightForOverflow
    setHeight() {
        this.el.style.height = 'auto';
        this.config.height = this.placeholder.firstElementChild.getBoundingClientRect().height;
        this.el.style.height = this.config.height + 'px';
    }

    setWidth() {
        this.el.style.width = 'auto';
        // this.el.style.width = this.placeholder.getBoundingClientRect().width + 'px';
        this.el.style.minWidth = this.placeholder.getBoundingClientRect().width + 'px';
    }

    setStyle() {
        this.setHeight();
        this.setWidth();
    }

    createElement($el, $value) {
        let div = document.createElement('div');
        div.innerHTML = $value;
        $el.appendChild(div);
    }

    rotate() {
        this.insertNewRecords(this.wrapper, this.data);
        let childNodes = this.wrapper.childNodes;
        let childIndex = childNodes.length - this.config.offestBottom;
        let scrolltoChild = childNodes[childIndex];
        anime({
            targets: this.el,
            scrollTop: scrolltoChild.offsetTop,
            duration: 1000,
            easing: 'easeInOutQuad',
            complete: (anim) => {
                this.createSlotWrapper();
                this.wrapper.appendChild(scrolltoChild);
                setTimeout(() => {
                    this.rotate();
                }, this.config.timeBetween);
            }
        });
    }


    insertNewRecords($el, $data) {
        let shuffle = _shuffle($data);
        for (let i = 0; i < shuffle.length; i++) {
            let data = shuffle[i];
            this.createElement($el, data);
        }
    }

    createSlotWrapper() {
        if (this.wrapper) {
            this.el.removeChild(this.wrapper);
        }
        this.wrapper = document.createElement('div');
        this.wrapper.className = 'slot-wrapper';
        this.el.appendChild(this.wrapper);
    }
}
