import $ from "jquery";

require('../AO/jquery.validate');


$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});


function UrlToObject() {
    let list = {};
    if (location.href.split('#')[0].split('?')[1]) {
        const $list = location.href.split('#')[0].split('?')[1].split('&');

        for (let $item of $list) {
            let split = $item.split('=');
            list[split[0]] = split[1];
        }
        return list;
    }
    return list
}

/** ----------------------------------------
 Add rules
 ---------------------------------------- */

/**
 * Add custom validation rules
 */

$.validator.addMethod("email", function (value, element) {
    return this.optional(element) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
}, 'Enter een geldig e-mailadres');


export class FormValidation {
    constructor(element, options = {}) {
        this.Form = typeof element === 'string' ? $(element) : element;
        this.config = FormValidation.mergeSettings(options);
        this.config.prefill = $.extend(true, {}, this.config.prefill, this.Form.data('default'));

        if (this.config.allowUrlOverwrite) {
            this.config.prefill = $.extend(true, {}, this.config.prefill, UrlToObject());
        }


        // Bind all event handlers for referencability
        [].forEach(method => {
            this[method] = this[method].bind(this);
        });


        this.init();

    }


    /**
     * Overrides default settings with custom ones.
     * @param {Object} options - Optional settings object.
     * @returns {Object} - Custom Siema settings.
     */
    static mergeSettings(options) {
        const settings = {
            allowUrlOverwrite: false,
            validClass: 'is-valid',
            invalidClass: 'is-invalid',
            inputGroup: 'form__group',
            loading: 'Loading...',
            error: 'X',
            button: '',
            prefill: {},
            rules: {},
            messages: {},
            onInit: () => {
            },
            beforeSubmit: () => {
            },
            afterSubmit: () => {
            },
            onSubmitSuccess: (res) => {
            },
            onSubmitFailed: (res) => {
            },

        };
        return $.extend(true, {}, settings, options);
    }


    init() {
        this.setValidationOptions();
        this.setValidation();
        this.toggleWatcher();
        this.defaultValue();


        this.config.onInit.call(this);
    }

    setValidationOptions() {
        const _this = this;

        this.ValidationOptions = {
            groups: {},
            highlight: function (element) {
                $(element).closest('.' + _this.config.inputGroup).addClass(_this.config.invalidClass).removeClass(_this.config.validClass);
            },
            unhighlight: function (element) {
                $(element).closest('.' + _this.config.inputGroup).removeClass(_this.config.invalidClass).addClass(_this.config.validClass);
            },
            errorPlacement: function (error, element) {
                $(element).closest('.' + _this.config.inputGroup).find('.input__error').append(error);
            },
        };

        let nestedGroups = this.Form.find('[data-nested-group]');
        if (nestedGroups.length) {
            for (let nestedGroup of nestedGroups) {
                let groupname = {};

                let inputs = $(nestedGroup).find(':input');
                if (inputs.length) {
                    let list = [];
                    for (let input of inputs) {
                        list.push(input.getAttribute('name'))
                    }
                    groupname = {
                        [nestedGroup.dataset.nestedGroup]: list.join(' ')
                    };
                }
                this.ValidationOptions.groups = {...this.ValidationOptions.groups, ...groupname}
            }
        }
        $.extend($.validator.messages, this.config.messages);

    }


    setValidation() {
        const _this = this;
        _this.Form.validate($.extend(_this.ValidationOptions, _this.config.rules, {
            submitHandler: function () {
                _this.config.beforeSubmit();
                // if form has Recaptcha
                if (_this.Form.find('[data-sitekey]').length) {
                    if (grecaptcha.getResponse()) {
                        $('.g-recaptcha div div').css('border', 'none');
                    } else {
                        $('.g-recaptcha div div').css('border', '1px solid red');
                        return false;
                    }
                }

                _this.setLoader();

                $.ajax({
                    url: _this.Form.attr('action'),
                    method: 'POST',
                    data: _this.Form.serialize(),
                }).done(function (res) {
                    if (res.hasOwnProperty('url')) {
                        window.location.href = res.url;
                    }

                    setTimeout(() => {
                        _this.config.onSubmitSuccess(res);
                        _this.isLoaded();
                    }, 1000);

                }).fail(function (res) {
                    _this.Form.find('.form-error').text(res.responseJSON.message);
                    _this.config.onSubmitFailed(res.responseJSON);
                    _this.isError();
                });
            }

        }));
    }

    toggleWatcher() {
        const _this = this;
        this.Form.find("[data-toggle]").on('change', (e) => {
            let target = e.target,
                name = target.getAttribute('name'),
                value = target.value,
                all_targets = _this.element.querySelectorAll("[data-toggle-target]");

            if (target.getAttribute('type') === 'checkbox') {
                value = target.checked ? '1' : '0';
            }

            for (let i = 0; i < all_targets.length; i++) {
                let element = all_targets[i];
                if (element.getAttribute('data-toggle-target') === name) {
                    element.style.display = "none";
                    if (element.getAttribute('data-toggle-value') === value || element.getAttribute('data-toggle-value') === '') {
                        element.style.display = "block";
                    } else {
                        element.style.display = "none";
                    }
                }
            }
        });
    }

    defaultValue() {
        let _this = this;
        const list = _this.config.prefill;
        Object.keys(list).forEach(function (key) {

            let items = $(`[name=${key}]`);

            if (!items.length) return false;

            let TYPE = items[0].getAttribute('type');
            let VALUE = list[key];

            if (TYPE === 'radio') {
                for (let i = 0; i < items.length; i++) {
                    let div = items[i];
                    if (div.getAttribute('value') === VALUE) {
                        div.checked = true;
                        div.dispatchEvent(new Event('change'));
                    }
                }
            }

            if (TYPE === 'text') {
                items[0].value = VALUE;
            }
        });
    }


    setLoader() {
        const button = this.config.button;
        button.style.width = button.offsetWidth + 'px';
        this.button_text = button.innerHTML;
        button.innerHTML = this.config.loading;
    }

    isLoaded() {
        this.Form[0].reset();
        this.removeLoader();
        this.config.afterSubmit.call(this);
    }

    removeLoader() {
        this.config.button.innerHTML = this.button_text;
        this.config.button.style.width = '';
    }

    isError() {
        this.config.button.innerHTML = this.config.error;

        setTimeout(() => {
            this.removeLoader();
        }, 4000)
    }


}
