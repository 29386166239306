/**
 * Trigger element needs:
 * [data-iframe] with the url of the iframe
 * [data-iframe-id] with an id of the iframe
 * [data-iframe-image-id] with an id of the thumbnail
 */

function ParamAdd(string, name, value) {
    if (string.indexOf(name) === -1) {
        let $arr = string.split('?');
        if ($arr.length > 1 && $arr[1].length > 0) {
            string += '&';
        }
        string += name + '=' + value;
    }
    return string;
}


function makeSrcReadyVimeo(src) {
    // src = ParamAdd(src, 'controls', 0);
    src = ParamAdd(src, 'dnt', 1);
    src = ParamAdd(src, 'transparent', 0);
    return src;
}


function makeSrcReadyYoutube(src) {
    return src;
}

function makeSrcReady(src) {
    if (src.indexOf('?') === -1) {
        src += '?';
    }

    src = ParamAdd(src, 'autoplay', 1);

    if (src.indexOf('youtube') > -1) {
        src = makeSrcReadyYoutube(src);
    } else if (src.indexOf('vimeo') > -1) {
        src = makeSrcReadyVimeo(src);
    }


    return src;
}

export class Videoplayer {
    constructor() {
        this.playbuttons = document.querySelectorAll('[data-iframe]');

        this.init();
    }

    init() {
        if (this.playbuttons.length) {
            for (let $playbutton of this.playbuttons) {
                if (
                    $playbutton.dataset.hasOwnProperty('iframe') &&
                    $playbutton.dataset['iframe'].length &&
                    $playbutton.dataset.hasOwnProperty('iframeId') &&
                    $playbutton.dataset['iframeId'].length &&
                    $playbutton.dataset.hasOwnProperty('iframeImageId') &&
                    $playbutton.dataset['iframeImageId'].length
                ) {
                    let container = document.getElementById($playbutton.dataset['iframeId']);
                    container.style.display = 'none';
                    $playbutton.onclick = function () {

                        let image = document.getElementById($playbutton.dataset['iframeImageId']);
                        image.style.display = 'none';
                        let iframe;
                        if (container.nodeName === 'IFRAME') {
                            iframe = container;
                        } else {
                            container.style.display = 'block';
                            iframe = container.querySelector('iframe');
                        }
                        iframe.src = makeSrcReady($playbutton.dataset['iframe']);
                        iframe.style.display = 'block';
                    }
                }
            }
        }
        return false;
    }
}
