import {Slots} from "./modules/Slots";
import {Videoplayer} from './modules/videoplayer';
import Menu from './modules/menu';
import {LazyImages} from "./modules/lazyImages";
import {FormValidation} from "./modules/FormValidation";

require('lity');

const $window = $(window);
const $body = $('body');

new Videoplayer();
new LazyImages($body, 200);

const $loader = document.querySelector('[data-iframe]');

if ($loader) {
    setTimeout(() => {
        let iframeUrl = $loader.dataset.iframe;
        let iframe = $loader.querySelector('iframe');
        iframe.src = iframeUrl;
        //
        iframe.addEventListener('load', function () {
            iframe.style.display = 'block';
            setTimeout(() => $loader.style.backgroundImage = 'none', 3000)
        });
    }, 3000);
}


const slots = document.getElementsByClassName('slot');
if (slots.length) {
    for (let i = 0; i < slots.length; i++) {
        new Slots(slots[i], 500 * i);
    }
}

let $dropdown = $('.dropdown');
let $dropdownHead = $('.dropdown__head');

if ($dropdown) {
    $($dropdownHead).on('click', function () {
        dropdown();
    });
}

let forms = document.getElementsByClassName('form');

for (let i = 0; i < forms.length; i++) {
    let form = forms[i];

    // Select radio/text field
    let otherCheckbox = $(form).find($('#other'));
    let otherText = $(form).find($("input[name='other']"));

    if (otherCheckbox && otherText) {
        otherCheckbox.on('click', function () {
            otherText.focus();
        });

        otherText.on('input, click', function () {
            otherCheckbox.prop("checked", true);
        });
    }

    new FormValidation($(form), {
        inputGroup: 'input',
        invalidClass: 'is-invalid',
        loading: '<i class="fa fa-spinner fa-spin"></i>',
        button: form.querySelector('button'),
        error: '',
        onSubmitSuccess: (res) => {
            let beforeElement = $(form).closest('.before-submit');
            let afterElement = $(beforeElement).siblings('.after-submit');
            beforeElement.hide();
            afterElement.show();
        },
        beforeSubmit: () => {
            if(!$('.checkbox__icon').is(':checked')) {
                if ($dropdown) {
                    dropdown();
                }
            }
        },
    });
}

function dropdown() {
    let $dropdownBody = $('.dropdown__body');

    if ($dropdown.hasClass('active')) {
        $dropdown.removeClass('active');
        $dropdownBody.stop(true, true).slideUp();
    } else {
        $dropdown.addClass('active');
        $dropdownBody.slideDown();
    }
}

new Menu();

let dHeight = window.innerHeight;
let maxPercentage = 0.8;
let nav = document.querySelector('nav');

function setRGBA($value) {
    nav.style.backgroundColor = `rgba(0,0,0,${$value})`;
}

function menuBgChecker() {
    let percentage = window.scrollY / dHeight;
    percentage > maxPercentage ? setRGBA(maxPercentage) : setRGBA(percentage);
}

menuBgChecker();
window.addEventListener('scroll', menuBgChecker);
