import anime from 'animejs';

export default class {
    constructor() {
        this.open = false;
        this.isAnimate = false;
        this.menu = document.querySelector('.menu');
        this.html = document.querySelector('html');
        this.underlay = document.querySelector('.menu-underlay');
        this.nav = document.querySelector('nav');
        if (this.menu) {
            this.triggers = document.querySelectorAll('[data-go-to]');
            this.menu_items = this.menu.querySelectorAll('.menu-anchors__item-link');
            this.hamburger = document.querySelector('.hamburgers');
            this.init();
            this.activeLinkOnScroll();
            this.onAnchorClick();
        }
    }

    init() {
        this.hamburger.addEventListener('click', () => {
            this.open ? this.closeMenu() : this.openMenu()
        });
        this.menu.classList.add('init');
        this.underlay.addEventListener('click', () => this.fastCloseMenu());

    }

    fastCloseMenu() {
        this.open = false;
        this.hamburger.classList.remove('open');
        this.menu.classList.remove('open');
        this.underlay.classList.remove('is-active');
        setTimeout(() => {
            this.html.classList.remove('is-locked');
        }, 500);
    }

    // Menu animation
    closeMenu() {
        if (this.isAnimate) {
            return false;
        }
        this.startAnimate();
        this.open = false;

        this.hamburger.classList.remove('open');
        this.menu.classList.remove('open');
        setTimeout(() => this.endAnimate(), 500);
        this.html.classList.remove('is-locked');
        this.underlay.classList.remove('is-active');

    }

    openMenu() {
        if (this.isAnimate) {
            return false;
        }
        this.html.classList.add('is-locked');
        this.startAnimate();
        this.open = true;
        this.hamburger.classList.add('open');
        this.menu.classList.add('open');
        this.underlay.classList.add('is-active');

        setTimeout(() => {
            this.endAnimate();
        }, 110)
    }

    startAnimate() {
        this.isAnimate = true
    }

    endAnimate() {
        this.isAnimate = false;
    }

    // End menu animation


    // active menu item
    activeLinkOnScroll() {

        this.activeEl = null;
        let anchors = document.querySelectorAll('[data-scroll]');
        let menu = document.querySelector('.menu');

        const activate = (el) => {
            if (el === null) {
                menu.classList.remove('has-menu');
                for (let item of this.menu_items) {
                    item.classList.remove('is-active')
                }
            } else {
                menu.classList.add('has-menu');

                for (let item of this.menu_items) {
                    item.dataset.goTo === el ? item.classList.add('is-active') : item.classList.remove('is-active')
                }

            }
        };

        window.addEventListener('scroll', () => {
            let window_height = window.innerHeight;
            let current_position = window.scrollY;
            let document_height = document.body.clientHeight;

            if ((document_height - (current_position + window_height)) < 10) {
                activate(anchors[anchors.length - 1].dataset.scroll);
            } else {
                let activeEl = null;

                for (let anchor of anchors) {
                    let top = (anchor.offsetTop - this.nav.clientHeight) - 10;
                    if (current_position >= top) {
                        activeEl = anchor;
                    }
                }

                // if (this.activeEl !== activeEl) {
                activate(activeEl ? activeEl.dataset.scroll : null);
                this.activeEl = activeEl
                // }
            }
        });
    }

    // end active menu item

    onAnchorClick() {
        if (this.triggers) {
            for (let menuItems of this.triggers) {
                menuItems.addEventListener('click', (e) => {

                    this.fastCloseMenu();
                    let id = e.target.dataset.goTo;
                    if (!id) {
                        return false;
                    }
                    let goTo = document.querySelector(`[data-scroll=${id}]`);
                    const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
                    anime({
                        targets: scrollElement,
                        scrollTop: (goTo.offsetTop - this.nav.clientHeight) - 10,
                        duration: 1000,
                        easing: 'easeInOutQuad'
                    });
                });
            }
        }
    }
}
